import { useProgress } from "@react-three/drei";
import { useEffect, useRef } from "react";
import gsap from 'gsap'
import '../styles/mobileLoader.css'

export default function MobileLoader(){

    const container = useRef()
    const { active, progress, errors, item, loaded, total } = useProgress()

    let tl

    useEffect(()=>{
        if(progress === 100){
            tl = new gsap.timeline()
            tl.to('.mobileLoaderContainer span',{opacity : 0 , duration : 1},1)               
            tl.to('.mobileLoaderContainer h2',{opacity : 0 , duration : 1},1)
            tl.to('.mobileLoaderContainer h3',{bottom : '30%', duration : 1},1.7)
            tl.to('.mobileLoaderContainer h4',{bottom : '25%', duration : 1},1.7)
            tl.to('.mobileLoaderContainer img',{top : '25%', duration : 1},1.7)
            tl.to('.mobileLoaderContainer',{ opacity : 0, duration : 1,
                onComplete : ()=>{container.current.style.display = 'none'}
            },2.7)
        }       
        
        return (()=>{
            if(tl){
                let arrayOfTween = tl.getChildren()
                arrayOfTween[5].eventCallback("onComplete",null)
                tl.kill()                
            }            
        })
    },[progress])

    return(
        <div ref={container} className="mobileLoaderContainer">
            <img src='./logo/logoAE.png' alt='logo'/>                
            <h2>{Math.round(progress)}</h2>                
            <span style={{transform:`scaleX(${progress *0.01})`}}></span>
            <h3> CREATIVE DEVELOPER </h3>                  
            <h4> PORTFOLIO </h4>                  
        </div>
    )
}