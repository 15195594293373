import { createNewText } from "../HybridComponents/TextCreator.js"

export default function MobileTexts({midwifery,scene3D,boat,bike,bloomBlueMaterial,baseMaterial,github,twitter,linkedin,mail}){

    const exan = './fonts/Exan-Regular.ttf'
    const michroma = './fonts/Michroma-Regular.ttf'

    return(
        <> 
                        {/* {createNewText(text,rotation,position,fontSize,font,material,maxWidth,onClick,textAlign)} */}
        {/* HOME */}
        {createNewText('AUTHORIZED ACCESS',[-Math.PI * 0.5,0,Math.PI * 0.5],[-1.35,0,-5.74231],.35,exan,bloomBlueMaterial)}
        {createNewText('COFFEE 84 C',[-Math.PI * 0.5,0,Math.PI * 0.5],[-1.1,0,5.35],.55,exan,bloomBlueMaterial,2)}
        {createNewText('WEB DEVELOPMENT',[-Math.PI * 0.5,1.3,Math.PI * 0.5],[-5,6.5,1.8],.35,exan,bloomBlueMaterial)}

        {/* ABOUT */}
        {createNewText('ARTHUR ENGEL',[-Math.PI * 0.5,0.8,Math.PI * 0.5],[-2.1,7.6,-76.5],.45,michroma,bloomBlueMaterial)}
        {createNewText('FREELANCE',[-Math.PI * 0.5,0.8,Math.PI * 0.5],[-0.7,6.2,-76.5],.58,michroma,bloomBlueMaterial)}
        {createNewText('CREATIVE DEVELOPER',[-Math.PI * 0.5,0.8,Math.PI * 0.5],[-1.4,6.9,-76.5],.31,michroma,bloomBlueMaterial)}
        {createNewText('26',[-Math.PI * 0.5,0.8,Math.PI * 0.5],[0.3,5.23,-77.88],.4,michroma,bloomBlueMaterial)}
        {createNewText('ACTUALLY BASED IN',[-Math.PI * 0.5,0,Math.PI * 0.5],[3.7,0,-68.25],.6,michroma,baseMaterial)}
        {createNewText('MONTPELLIER, FRANCE',[-Math.PI * 0.5,0,Math.PI * 0.5],[3.7,0,-77.75],.6,michroma,bloomBlueMaterial)}
        {createNewText('I USE TECHNOLOGIES SUCH AS',[-Math.PI * 0.5,0,Math.PI * 0.5],[5.2,0,-68.6],.6,michroma,baseMaterial)}
        {createNewText('REACT AND THREE JS',[-Math.PI * 0.5,0,Math.PI * 0.5],[5.2,0,-79.8],.6,michroma,bloomBlueMaterial)}
        {createNewText('TO CREATE IMMERSIVE EXPERIENCES',[-Math.PI * 0.5,0,Math.PI * 0.5],[6,0,-73.3],.6,michroma,baseMaterial)}
        {createNewText('I CREATE ALL OF MY 3D MODELS WITH',[-Math.PI * 0.5,0,Math.PI * 0.5],[7.5,0,-71.2],.6,michroma,baseMaterial)}
        {createNewText('BLENDER',[-Math.PI * 0.5,0,Math.PI * 0.5],[7.5,0,-81.45],.6,michroma,bloomBlueMaterial)}
        {createNewText('I ALSO HAVE A KEEN INTEREST IN THE',[-Math.PI * 0.5,0,Math.PI * 0.5],[9,0,-73.3],.6,michroma,baseMaterial)}
        {createNewText('BLOCKCHAIN ECOSYSTEM',[-Math.PI * 0.5,0,Math.PI * 0.5],[9.9,0,-73.3],.6,michroma,bloomBlueMaterial)}
     
        {/* CONTACT */}
        {createNewText('GITHUB',[-Math.PI * 0.5,0,Math.PI * 0.5],[57.2, -0.25, -2],.4,michroma,bloomBlueMaterial,10,github)}
        {createNewText('TWITTER',[-Math.PI * 0.5,0,Math.PI * 0.5],[59.35, -0.25, -6.8],.4,michroma,bloomBlueMaterial,10,twitter)}
        {createNewText('LINKEDIN',[-Math.PI * 0.5,0,Math.PI * 0.5],[64, -0.25, -4.9],.4,michroma,bloomBlueMaterial,10,linkedin)}
        {createNewText('MAIL',[-Math.PI * 0.5,0,Math.PI * 0.5],[60.6, -0.25, 1.4],.4,michroma,bloomBlueMaterial,10,mail)}
        {createNewText(`LET'S SHARE`,[-Math.PI * 0.5,0,Math.PI * 0.5],[62, -0.25, 1.4],.8,michroma,baseMaterial)}
        {createNewText('ABOUT YOUR',[-Math.PI * 0.5,0,Math.PI * 0.5],[63.2, -0.25, 1.4],.75,michroma,baseMaterial)}
        {createNewText('PROJECT',[-Math.PI * 0.5,0,Math.PI * 0.5],[64.4, -0.25, 1.4],1.065,michroma,baseMaterial)}
        {createNewText('CONTACT',[-Math.PI * 0.5,0,Math.PI * 0.5],[65.8, -0.25, 1.4],1.02,michroma,bloomBlueMaterial)}
        {createNewText('ME',[-Math.PI * 0.5,0,Math.PI * 0.5],[65.8, -0.25, -3.8],1.05,michroma,baseMaterial)}

        {/* PROJECTS */}
        {createNewText('PORTFOLIO',[0,Math.PI *0.96,0],[61.8676,17.7518,-80.8751],.85,michroma,bloomBlueMaterial)}
        {createNewText('SHIP SIMULATOR',[0,Math.PI *0.56,0],[68.9476,17.7518,-74.2266],.85,michroma,bloomBlueMaterial)}
        {createNewText('3D SCENE',[0,Math.PI * 0.16,0],[64.8123,17.7518,-65.4387],.85,michroma,bloomBlueMaterial)}
        {createNewText('MIDWIFERY',[0,-Math.PI * 0.24,0],[55.1766, 17.7518, -66.656],.85,michroma,bloomBlueMaterial)}
        {createNewText('BIKE CONCEPT',[0,-Math.PI * 0.64,0],[53.3567,17.7518,-76.1962],.85,michroma,bloomBlueMaterial)}              
        {createNewText('INTERACTIVE 3D SCENE INTEGRATED ON THE WEB REPRESENTING MY WORKSPACE',
            [0,Math.PI * 0.16,0],[64.8123,7.1,-65.4387],.5,michroma,baseMaterial,10,null,'center'
        )}
        {createNewText('WEBSITE FOR A MIDWIFE DESCRIBING HER PRACTICE AND THE CARE SHE PROVIDES',
            [0,-Math.PI * 0.24,0],[55.1766,7.1,-66.656],.5,michroma,baseMaterial,10,null,'center'
        )}
        {createNewText('A PORTFOLIO HIGHLIGHTING THE PROJECTS I HAVE COMPLETED TO SHOW MY SKILLS TO CLIENTS',
            [0,Math.PI *0.96,0],[61.8676,7.1,-80.8751],.5,michroma,baseMaterial,10,null,'center'
        )}
        {createNewText('CONTROL SIMULATION OF AN ANCIENT SHIP RESPONSIVE TO 3D PHYSICS',
            [0,Math.PI *0.56,0],[68.9476,7.1,-74.2266],.5,michroma,baseMaterial,10,null,'center'
        )}
        {createNewText('AN INTERACTIVE PRODUCT PAGE HIGHLIGHTING ALL THE FEATURES OF A FICTIONAL BIKE',
            [0,-Math.PI * 0.64,0],[53.3567,7.1,-76.1962],.5,michroma,baseMaterial,10,null,'center'
        )}
        {createNewText('VISIT THE WEBSITE',[0,Math.PI *0.56,0],[68.9476,4.2,-74.2266],.45,michroma,baseMaterial,10,boat)}
        {createNewText('VISIT THE WEBSITE',[0,-Math.PI * 0.24,0],[55.1766, 4.2, -66.656],.45,michroma,baseMaterial,10,midwifery)}
        {createNewText('VISIT THE WEBSITE',[0,Math.PI * 0.16,0],[64.8123,4.2,-65.4387],.45,michroma,baseMaterial,10,scene3D)}
        {createNewText('VISIT THE WEBSITE',[0,-Math.PI * 0.64,0],[53.3567,4.2,-76.1962],.45,michroma,baseMaterial,10,bike)}
  
        </>
    )
}